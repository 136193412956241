import { defineStore } from "pinia";
import { IUser } from "server/models/user";
import { record, input } from "@/composables/store";

export const useUser = defineStore("user", () => {
	const mock = ref<boolean>(false);

	const token = useCookie("__session");

	const isAuthenticated = computed(() => {
		return token.value ? true : false;
	});

	const user = ref<IUser | null>(null);

	const load = async function (reload: boolean = false): Promise<void> {
		if (user.value && reload === false) return;

		try {
			const result = await record<IUser>("users/me");
			if (user.value) {
				Object.assign(user.value, result);
			} else {
				user.value = result;
			}
		} catch (error) {
			user.value = null;
			//console.error(error);
			throw error;
		}
	};

	const save = async function (): Promise<void> {
		await input<IUser>("users/me", user.value!);
		return;
	};

	return {
		save,
		mock,
		load,
		user,
		token,
		isAuthenticated,
	};
});
