import { APIDocumentResponse, APIListResponse } from "server/routes/mock/index.get";
import { useUser } from "@/stores/user";

export async function query<T>(path: string, id?: string, query: any = {}): Promise<{ list: T[]; length: number; id: string; exported: number }> {
	const token = useUser().token;

	Object.keys(query).forEach((key) => (query[key] === undefined ? delete query[key] : {}));

	const url = getHostUrl() + `/${path}` + buildQueryString(query);

	if (!id) {
		id = url;
	}

	const options: any = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token ? `Bearer ${token}` : "",
		},
	};

	//console.log("store.query", url, options);

	const data = await fetch(url, options);

	if (data.status !== 200) throw new Error(`Error fetching ${path}`);

	const json = (await data.json()) as APIListResponse<T>;

	if (json.success !== true) throw new Error(`Error fetching ${path}`);

	return { list: json.list, length: json.length, id, exported: json.exported ? json.exported : 0 };
}

export async function record<T>(path: string, query: any = {}): Promise<T> {
	const token = useUser().token;

	const url = getHostUrl() + `/${path}` + buildQueryString(query);

	const options: any = {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: token ? `Bearer ${token}` : "",
		},
	};

	//console.log("store.record", url, options);

	const data = await fetch(url, options);

	if (data.status !== 200) throw new Error(`Error fetching ${path}`);

	const json = (await data.json()) as APIDocumentResponse<T>;

	if (json.success !== true) throw new Error(`Error fetching ${path}`);

	return json.data;
}

export async function input<T>(path: string, body: any, query: any = {}, method: string = "PUT", jsonType: boolean = true): Promise<T> {
	const token = useUser().token;

	const url = getHostUrl() + `/${path}` + buildQueryString(query);

	const headers: any = {
		Authorization: token ? `Bearer ${token}` : "",
	};

	if (jsonType) {
		headers["Content-Type"] = "application/json";
	}

	const options: any = {
		method,
		headers,
		body: jsonType ? JSON.stringify(body) : body,
	};

	//console.log("store.input", url, options);

	const data = await fetch(url, options);

	if (data.status !== 200) throw new Error(`Error fetching ${path}`);

	const json = (await data.json()) as APIDocumentResponse<T>;

	if (json.success !== true) throw new Error(`Error fetching ${path}`);

	return json.data;
}

export function getHostUrl() {
	const config = useRuntimeConfig();

	if (process.server) {
		return config.host_url + "/api";
	} else {
		return window.location.origin + "/api";
	}
}

function buildQueryString(query: any = {}) {
	if (Object.keys(query).length === 0) return "";

	// Remove empty values
	Object.keys(query).forEach((key) => (query[key] === undefined || query[key] === "{}" ? delete query[key] : {}));

	const processed: any = {
		...query,
	};

	return (
		"?" +
		Object.keys(processed)
			.map((key) => key + "=" + processed[key])
			.join("&")
	);
}
