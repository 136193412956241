import { RouteLocationNormalized } from ".nuxt/vue-router";

export async function getTransitionName(to?: RouteLocationNormalized, from?: RouteLocationNormalized) {
	if (!to || !from) return;

	const pages: string[] = ["companies", "contacts", "enrichment", "exports", "support", "account-profile", "account-billing", "account-invoices", "account-credit-history"];
	if (pages.includes(to.name as string) && pages.includes(from.name as string)) {
		if (pages.indexOf(to.name as string) > pages.indexOf(from.name as string)) {
			to.meta.pageTransition = { name: "page-up" };
			from.meta.pageTransition = { name: "page-up" };
		} else {
			to.meta.pageTransition = { name: "page-down" };
			from.meta.pageTransition = { name: "page-down" };
		}
	}
}
