import { useUser } from "~/stores/user";
import { Pinia } from "pinia";

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (process.server) {
		if (to.path.includes("auth") || to.path.includes("api")) {
			return;
		}

		const cookie = useCookie("__session");
		//console.log("auth.global", to.path, cookie.value);

		if (to.path === "/") {
			// If user has a cookie session and visiting login, redirect to companies page
			if (cookie.value) {
				return navigateTo("/companies");
			}
		} else {
			// If user has no cookie session and visiting any page other than login, redirect to login
			if (!cookie.value) {
				return navigateTo("/");
			} else {
				// If user has a cookie session and visiting any page other than login, check if it is valid
				const app = useNuxtApp();
				const { load } = useUser(app.$pinia as Pinia);

				try {
					await load();
				} catch (error) {
					return navigateTo("/auth/logout");
				}
			}
		}
	}
});
