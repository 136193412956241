import { default as billingCeWOVo5lPbMeta } from "/vercel/path0/pages/account/billing.vue?macro=true";
import { default as credit_45history1QnE5jINaZMeta } from "/vercel/path0/pages/account/credit-history.vue?macro=true";
import { default as integrations0m1GJSrPVIMeta } from "/vercel/path0/pages/account/integrations.vue?macro=true";
import { default as invoicesjLp5A0OxReMeta } from "/vercel/path0/pages/account/invoices.vue?macro=true";
import { default as profileLQWMAtlTUmMeta } from "/vercel/path0/pages/account/profile.vue?macro=true";
import { default as exportq0B7JbomRIMeta } from "/vercel/path0/pages/companies/export.vue?macro=true";
import { default as companiesd8HmMpceeyMeta } from "/vercel/path0/pages/companies.vue?macro=true";
import { default as componentsRfOnVfXFhhMeta } from "/vercel/path0/pages/components.vue?macro=true";
import { default as exportBOGKwlnGSFMeta } from "/vercel/path0/pages/contacts/export.vue?macro=true";
import { default as contactsvmO9gwdyLiMeta } from "/vercel/path0/pages/contacts.vue?macro=true";
import { default as enrichmentjSnf9k26HxMeta } from "/vercel/path0/pages/enrichment.vue?macro=true";
import { default as exportsEeLI9LX3MDMeta } from "/vercel/path0/pages/exports.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as supportJTqUQCOdxvMeta } from "/vercel/path0/pages/support.vue?macro=true";
export default [
  {
    name: billingCeWOVo5lPbMeta?.name ?? "account-billing",
    path: billingCeWOVo5lPbMeta?.path ?? "/account/billing",
    meta: billingCeWOVo5lPbMeta || {},
    alias: billingCeWOVo5lPbMeta?.alias || [],
    redirect: billingCeWOVo5lPbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: credit_45history1QnE5jINaZMeta?.name ?? "account-credit-history",
    path: credit_45history1QnE5jINaZMeta?.path ?? "/account/credit-history",
    meta: credit_45history1QnE5jINaZMeta || {},
    alias: credit_45history1QnE5jINaZMeta?.alias || [],
    redirect: credit_45history1QnE5jINaZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/credit-history.vue").then(m => m.default || m)
  },
  {
    name: integrations0m1GJSrPVIMeta?.name ?? "account-integrations",
    path: integrations0m1GJSrPVIMeta?.path ?? "/account/integrations",
    meta: integrations0m1GJSrPVIMeta || {},
    alias: integrations0m1GJSrPVIMeta?.alias || [],
    redirect: integrations0m1GJSrPVIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/integrations.vue").then(m => m.default || m)
  },
  {
    name: invoicesjLp5A0OxReMeta?.name ?? "account-invoices",
    path: invoicesjLp5A0OxReMeta?.path ?? "/account/invoices",
    meta: invoicesjLp5A0OxReMeta || {},
    alias: invoicesjLp5A0OxReMeta?.alias || [],
    redirect: invoicesjLp5A0OxReMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/invoices.vue").then(m => m.default || m)
  },
  {
    name: profileLQWMAtlTUmMeta?.name ?? "account-profile",
    path: profileLQWMAtlTUmMeta?.path ?? "/account/profile",
    meta: profileLQWMAtlTUmMeta || {},
    alias: profileLQWMAtlTUmMeta?.alias || [],
    redirect: profileLQWMAtlTUmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: companiesd8HmMpceeyMeta?.name ?? "companies",
    path: companiesd8HmMpceeyMeta?.path ?? "/companies",
    children: [
  {
    name: exportq0B7JbomRIMeta?.name ?? "companies-export",
    path: exportq0B7JbomRIMeta?.path ?? "export",
    meta: exportq0B7JbomRIMeta || {},
    alias: exportq0B7JbomRIMeta?.alias || [],
    redirect: exportq0B7JbomRIMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/companies/export.vue").then(m => m.default || m)
  }
],
    meta: companiesd8HmMpceeyMeta || {},
    alias: companiesd8HmMpceeyMeta?.alias || [],
    redirect: companiesd8HmMpceeyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/companies.vue").then(m => m.default || m)
  },
  {
    name: componentsRfOnVfXFhhMeta?.name ?? "components",
    path: componentsRfOnVfXFhhMeta?.path ?? "/components",
    meta: componentsRfOnVfXFhhMeta || {},
    alias: componentsRfOnVfXFhhMeta?.alias || [],
    redirect: componentsRfOnVfXFhhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/components.vue").then(m => m.default || m)
  },
  {
    name: contactsvmO9gwdyLiMeta?.name ?? "contacts",
    path: contactsvmO9gwdyLiMeta?.path ?? "/contacts",
    children: [
  {
    name: exportBOGKwlnGSFMeta?.name ?? "contacts-export",
    path: exportBOGKwlnGSFMeta?.path ?? "export",
    meta: exportBOGKwlnGSFMeta || {},
    alias: exportBOGKwlnGSFMeta?.alias || [],
    redirect: exportBOGKwlnGSFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contacts/export.vue").then(m => m.default || m)
  }
],
    meta: contactsvmO9gwdyLiMeta || {},
    alias: contactsvmO9gwdyLiMeta?.alias || [],
    redirect: contactsvmO9gwdyLiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: enrichmentjSnf9k26HxMeta?.name ?? "enrichment",
    path: enrichmentjSnf9k26HxMeta?.path ?? "/enrichment",
    meta: enrichmentjSnf9k26HxMeta || {},
    alias: enrichmentjSnf9k26HxMeta?.alias || [],
    redirect: enrichmentjSnf9k26HxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/enrichment.vue").then(m => m.default || m)
  },
  {
    name: exportsEeLI9LX3MDMeta?.name ?? "exports",
    path: exportsEeLI9LX3MDMeta?.path ?? "/exports",
    meta: exportsEeLI9LX3MDMeta || {},
    alias: exportsEeLI9LX3MDMeta?.alias || [],
    redirect: exportsEeLI9LX3MDMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/exports.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: supportJTqUQCOdxvMeta?.name ?? "support",
    path: supportJTqUQCOdxvMeta?.path ?? "/support",
    meta: supportJTqUQCOdxvMeta || {},
    alias: supportJTqUQCOdxvMeta?.alias || [],
    redirect: supportJTqUQCOdxvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/support.vue").then(m => m.default || m)
  }
]